import Carousel from 'react-bootstrap/Carousel';

function WareHouse() {
  return (
    <Carousel  indicators={false} className="goals-carousel" >
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/2a.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/2b.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/2c.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/2d.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/2e.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/2f.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/2g.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/2h.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>

       

    </Carousel>
  );
}

export default WareHouse;