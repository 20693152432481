import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Projects | Gimlovu Environmental Consult Ltd';

},[]);

  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./images/2.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0',
    paddingTop:'12.5rem'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3 quote-h3-a'>Ongoing & Completed Projects</h2>
            
        </div>
    </section>
    <section className='section-cover team-cover' >
         <div className='content-container table-container'>
           {/* <h2 className="home-h2 team-h2">Our Team</h2> */}
          
          

  <table className='team-table'>
     
    <tr>
      <th>No.</th>
      <th>Period </th>
      <th>Project Title</th>
    </tr>
    <tr>
      <td>1.</td>
      <td>2023 - ongoing</td>
      <td>Civil works for the periodic maintenance of Kampala Northern Bypass Phase 1 (21 KM).</td>
    </tr>
    <tr>
      <td>2.</td>
      <td>May 2022 - ongoing</td>
      <td>Construction of selected road infrastructure in Kamuli Municipal Council, Lugazi Municipal Council and Jinja City under batch 2- USMID-AF PROJECT – MLHUD.</td>
    </tr>
    <tr>
      <td>3.</td>
      <td>September 2021 - ongoing
</td>
      <td>
            <ul>
           <li> Upgrading of gravel/earth/surfaced roads to asphalt concrete ancillalies area in Kamuli Municipal Council including road ancillaries (industrial area road and Byaino road).</li>
           <li> Upgrading of gravel/ earth/surfaced road to asphalt concrete paved roads in Lugazi Municipality (Kulubya road - 0.36km, Kinyoro road - 0.86km, Nabugabo close - 0.30km, Nabugabo road - 0.36km, market street 01 - 0.4km, market street 03 - 0.18km & market street 04 - 0.06km).</li>
            </ul>
            </td>
    </tr>
     <tr>
      <td>4.</td>
      <td>July 2021 - ongoing
</td>
      <td>
           <ul>
           <li>  Embankment works on Nsango–Bulega swamp crossing between Bugiri and Butaleja districts (4.5km) located in Kisoko-Hasheba village Nsangaso parish, Buluguya sub county in Bugiri district. - under Bugiri District Local Government.</li>
<li>Construction of lecture and laboratory block for Busitema University maritime institute, Namasagali campus, Kamuli district.</li>

           </ul>
            </td>
    </tr>
    <tr>
      <td>5.</td>
      <td>August
2020 – 2021

</td>
      <td>
          The design and build of two ferry landing platforms and axillary facilities at Buvuma and Kiyindi --- under Ministry of Agriculture, Animal Industry and Fisheries (MAAIF), with support from the International Fund for Agricultural Development (IFAD).
     </td>
    </tr>
    <tr>
      <td>6.</td>
      <td>May to
June (2018)

</td>
      <td>
   Construction of the drainage infrastructure in Masaka Municipality.
            </td>
    </tr>
     <tr>
      <td>7.</td>
      <td>2021

</td>
      <td>
            <ul>
  <li>The proposed fuel station in Dwaliro Village, Kasanda
District.</li> 
<li>The proposed fuel station in Nalututu Village, Kasanda District.</li>
</ul>
            </td>
    </tr>
    <tr>
      <td>8.</td>
      <td>August
2020- 2022


</td>
      <td>
     Uganda Support to Municipal Infrastructure Development (USMID) program; funded by World Bank (WB) - Jinja main street, Busoga and Engineer Zikusooka roads in Jinja Municipality.
            </td>
    </tr>
     <tr>
      <td>9.</td>
      <td>November
2019 to
November
2020



</td>
      <td>
    Para-transit/informal transport system & street usage in Greater Kampala Metropolitan Area (GKMA) in view of the planned Bus Rapid Transit (BRT) pilot project. _under Ministry of Works and Transport and KCCA obtained funding from African Development Bank.
            </td>
    </tr>
    <tr>
      <td>10.</td>
      <td>2019 -
Jan 2020
</td>
      <td>
   <ul>
      <li>Manufacturing factories established in Liao Shen industrial park Kepeeka (plot m06 channel lane Bugolobi, P. O. Box 22994, Kampala – Uganda).</li>
      <li>Mining Projects for Kaolin and Clay for Hua Hui International Group Company Ltd Kapeeka</li>
      <li>Stone Quarry project located in Kapeka Sub county, Nakaseke District for Liao Shen industrial Park Plot M06 Channel Lane Bugolobi, P. O. Box 22994, Kampala – Uganda.</li>
   </ul>
            </td>
    </tr>
     <tr>
      <td>11.</td>
      <td>August 2018
</td>
      <td>
   Consultancy services for the feasibility study and detailed engineering design for Kampala Outer Beltway road project (KOB) under Uganda National Roads Authority (UNRA).
            </td>
    </tr>
    <tr>
      <td>12.</td>
      <td>May 2017

</td>
      <td>
   Consultancy services for the feasibility study and detailed engineering design for the VVIP express route (Nakasero – northern bypass expressway) under Uganda National Roads Authority (UNRA).
            </td>
    </tr>
    <tr>
      <td>13.</td>
      <td>March
2019 -
June
2019

</td>
      <td>
  Upgrading of Kapchorwa _ Suam road funded by African Development Bank under Uganda National Roads Authority (UNRA).
            </td>
    </tr>
    <tr>
      <td>14.</td>
      <td>Feb 2019

</td>
      <td>
  The construction of a helicopter hangar and other associated facilities at the flying school (East African Civil Aviation Academy) in Soroti Municipality under Uganda People’s Defense Force (UPDF) received funds from the United States Embassy.
            </td>
    </tr>
     <tr>
      <td>15.</td>
      <td>November
2018 

</td>
      <td>
  Design and build for civil works for the construction of strategic bridges in northern-region under Uganda National Roads Authority (UNRA).
            </td>
    </tr>

  
  </table>



 


         </div>
    </section>
     
    </>
  )
}

export default Team