import Carousel from 'react-bootstrap/Carousel';

function Residential1() {
  return (
    <Carousel indicators={false} className='goals-carousel'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/25a.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/25b.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/25c.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      
    </Carousel>
  );
}

export default Residential1;