import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import WhoWeAre from './pages/WhoWeAre';
import Team from './pages/Team';
import Projects from './pages/NewProjects';
import OngoingProjects from './pages/OngoingProjects';
import Equipment from './pages/Equipment';
import Services from './pages/Features';
import Clients from './pages/Clients';
import Gallery from './pages/Gallery';
import Certification from './pages/Certification';
import Contact from './pages/Contact';
import Telecom from './pages/Telecom';
import Power from './pages/Power';
import Roads from './pages/Roads';
import Support from './pages/Support';
import Maintenance from './pages/Maintenance';
import Response from './pages/Response';
import Construction from './pages/Construction';
import Compliance from './pages/Compliance';
import Error from './pages/Error';
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import TopSection from "./components/TopSection";
import WhatsAppIcon from "./components/WhatsAppIcon";
import BackToTop from "./components/BackToTop";
import Electrical from "./pages/Electrical-works";
import Solar from "./pages/Solar";
import Plumbing from "./pages/Plumbing";
import CCTV from "./pages/CCTV";
import SC from "./pages/SC";
import Wifi from "./pages/Wifi";
import IT from "./pages/ITServices";
import Features from "./pages/Features";
import Packages from "./pages/Packages";
import Products from "./pages/Products";
import Policies from "./pages/Certification";

function App() {
  return (
    <BrowserRouter>
    {/* <TopSection></TopSection> */}
      <Nav></Nav>
       <Routes>
            <Route path='/' element={<Home></Home>}></Route>
            <Route path='about' element={<WhoWeAre></WhoWeAre>}></Route>
            <Route path='team' element={<Team></Team>}></Route>
            <Route path='services' element={<Services></Services>}></Route>
            <Route path='clients' element={<Clients></Clients>}></Route>
            <Route path='gallery' element={<Gallery></Gallery>}></Route>
             <Route path='equipment' element={<Equipment></Equipment>}></Route>
             
            <Route path='policies' element={<Policies></Policies>}></Route>
             <Route path='inquiries' element={<Contact></Contact>}></Route>
             <Route path='*' element={<Error></Error>}></Route>
             {/* Footer Services Links */}
 <Route path='telecom' element={<Telecom></Telecom>}></Route>
 <Route path='power' element={<Power></Power>}></Route>
 <Route path='road-construction' element={<Roads></Roads>}></Route>
 <Route path='projects' element={<Projects></Projects>}></Route>
  <Route path='ongoing-projects' element={<OngoingProjects></OngoingProjects>}></Route>
  <Route path='support' element={<Support></Support>}></Route>
  <Route path='maintenance' element={<Maintenance></Maintenance>}></Route>
<Route path='emergency-response' element={<Response></Response>}></Route>
  <Route path='building-construction' element={<Construction></Construction>}></Route> 
   <Route path='regulatory-compliance' element={<Compliance></Compliance>}></Route>   
   <Route path='electrical-works' element={<Electrical></Electrical>}></Route>     
   <Route path='solar-power-services' element={<Solar></Solar>}></Route> 
   <Route path='plumbing-services' element={<Plumbing></Plumbing>}></Route> 
    <Route path='cctv-systems' element={<CCTV></CCTV>}></Route> 
    <Route path='structured-cabling' element={<SC></SC>}></Route> 
    <Route path='wi-fi-solutions' element={<Wifi></Wifi>}></Route> 
    <Route path='it-services' element={<IT></IT>}></Route>   
    <Route path='features' element={<Features></Features>}></Route> 
    <Route path='packages' element={<Packages></Packages>}></Route>         
    <Route path='products' element={<Products></Products>}></Route>

       </Routes>
       <WhatsAppIcon></WhatsAppIcon><BackToTop></BackToTop>
       <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
