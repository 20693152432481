import React, {useEffect} from 'react';
import Warehouse from '../components/WareHouse';
import CommercialBlock from '../components/CommercialBlock';
import Healthcenter from '../components/Healthcenter';
import TH from '../components/TH';
import ClassroomBlocks from '../components/ClassRoomBlocks';
import WaterSupply from '../components/WaterSupply';
import Hardware from '../components/Hardware';
import Residential1 from '../components/Residential1';
import Residential2 from '../components/Residential2';
import Residential3 from '../components/Residential3';
import Residential4 from '../components/Residential4';
import Residential5 from '../components/Residential5';
import Roads1 from '../components/Roads1';
import Land1 from '../components/Land1';
import Land2 from '../components/Land2';
import Farming from '../components/Farming';
import Bridge1 from '../components/Bridge1';
import Aluminium1 from '../components/Aluminium1';
import Commercial1 from '../components/Commercial1';
import Roads2 from '../components/Roads2';
import Excavation from '../components/Excavation';
import Warehouse1 from '../components/Warehouse1';
import Aswa1 from '../components/Aswa1';

const Gallery = () => {
  useEffect(()=>{
document.title = 'Gallery | Beckotech Engineering Limited';

},[]);
  return (
    <section className='section-cover gallery-cover' >
       <h2 className="home-h2 gallery-h2">Gallery</h2>
      <div className='content-container gallery'>
        {/* <article className='gallery-content'>
          <img src='./images/37.jpg' className='gallery-image' alt='image'></img>
           <Residential5></Residential5>
           <p>Construction of a residential house at Kasenge, Wakiso District </p>
         </article>
          */}


         <article className='gallery-content'>
         <Warehouse></Warehouse>
           <p>150T feed plant being constructed in Mukono Mbalala</p>
         </article>

          <article className='gallery-content'>
         <CommercialBlock></CommercialBlock>
           <p>Construction of a silo in Kyenjojo District Uganda</p>
         </article>

         <article className='gallery-content'>
         <Healthcenter></Healthcenter>
           <p>Part of our refinery work in Rwanda</p>
         </article>

          <article className='gallery-content'>
         <TH></TH>
           <p>Our refinery work at Maxfeed</p>
         </article>

         <article className='gallery-content'>
         <ClassroomBlocks></ClassroomBlocks>
           <p>A refinery construction in Mukono</p>
         </article>

         <article className='gallery-content'>
         <WaterSupply></WaterSupply>
           <p>Construction Works</p>
         </article>

         <article className='gallery-content'>
         <Hardware></Hardware>
           <p>Our floor work ready for silo</p>
         </article>

         {/* <article className='gallery-content'>
         <Residential1></Residential1>
           <p>Our floor work ready for silo</p>
         </article> */}

         <article className='gallery-content'>
         <Residential2></Residential2>
           <p>Animal feed plant and silos constructed at Namanve</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/10a.jpg' className='gallery-image' alt='image'></img>
           <p>Tank fabrication</p>
         </article>

         <article className='gallery-content'>
           <img src='./images/11a.jpg' className='gallery-image' alt='image'></img>
           <p>Crane erection</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/12a.jpg' className='gallery-image' alt='image'></img>
           <p>Silo erection</p>
         </article>

         <article className='gallery-content'>
         <Residential3></Residential3>
           <p>Preparation for the silo base casting</p>
         </article>

          <article className='gallery-content'>
         <Residential4></Residential4>
           <p>3G to 6G welding techniques</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/15a.jpg' className='gallery-image' alt='image'></img>
           <p>Refinery under construction</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/17a.jpg' className='gallery-image' alt='image'></img>
           <p>Piping work</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/18a.jpg' className='gallery-image' alt='image'></img>
           <p>Our fire bricks gives you the best quality boilers</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/19a.jpg' className='gallery-image' alt='image'></img>
           <p>We give you the best motor ceiling designs</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/20a.jpg' className='gallery-image' alt='image'></img>
           <p>A 40 tone boiler</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/21a.jpg' className='gallery-image' alt='image'></img>
           <p>Cooling tower pit</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/22a.jpg' className='gallery-image' alt='image'></img>
           <p>Steel reinforcement work for the boiler chimney</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/23a.jpg' className='gallery-image' alt='image'></img>
           <p>Feed plant</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/24a.jpg' className='gallery-image' alt='image'></img>
           <p>The new face of the roofing project</p>
         </article>

          <article className='gallery-content'>
          <Residential5></Residential5>
           <p>Cooling tower pit construction</p>
         </article>

         <article className='gallery-content'>
          <Roads1></Roads1>
           <p>Rice mills installation</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/27a.jpg' className='gallery-image' alt='image'></img>
           <p>The vacuum reactor system</p>
         </article>

         <article className='gallery-content'>
          <Land1></Land1>
           <p>A 1Ton/hour, 10.54kgf/cm2 boiler foundation underway</p>
         </article>

         <article className='gallery-content'>
          <Land2></Land2>
           <p>ID fan casing replacement in Kaliro Modern Sugar</p>
         </article>

         <article className='gallery-content'>
          <Farming></Farming>
           <p>Furniture work in our workshop</p>
         </article>

         <article className='gallery-content'>
          <Bridge1></Bridge1>
           <p>Kiln construction work in Tembo steels Iganga</p>
         </article>

         <article className='gallery-content'>
          <Aluminium1></Aluminium1>
           <p>Road Signages for Busia Municipal Council</p>
         </article>
         <article className='gallery-content'>
          <Roads2></Roads2>
           <p>Tembo steel works</p>
         </article>

         <article className='gallery-content'>
           <img src='./images/38.jpeg' className='gallery-image' alt='image'></img>
           <p>Electric fencing at Kenfreight Uganda Limited</p>
         </article>

          <article className='gallery-content'>
           <Commercial1></Commercial1>
           <p>200Ton per day refinery constructed in Nigeria</p>
         </article>
         <article className='gallery-content'>
           <Residential1></Residential1>
           <p>Office Renovation at Kenfreight Uganda Limited Malaba </p>
         </article>
 
      </div>

    </section>
  )
}

export default Gallery