import React from 'react';
import {Link} from 'react-router-dom'
import {FaChevronRight, FaFacebookSquare,FaYoutube, FaTwitterSquare, FaLinkedin} from 'react-icons/fa';
import {TfiYoutube} from 'react-icons/ti'

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}



const Footer = () => {

  return (
    <section className='section-cover footer-cover' style={{ backgroundImage: `linear-gradient(rgba(51, 19, 20, 0.8), rgba(51, 19, 20, 0.8)), url("./images/12.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
      <div className='footer-content'>

 



             <div className='footer-inner-content'>
                    <h3>Quick Links</h3>
                    <div className="footer-links-cover">
                        <div className='footer-link'>
                             <Link to='/' onClick={scrollFunc}>  <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Home</p></Link> 
                        </div>

                         <div className='footer-link'>
                              <Link to='about' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>About Us</p></Link> 
                        </div>
                        <div className='footer-link'>
                              <Link to='policies' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Policies</p></Link> 
                        </div>
                        <div className='footer-link'>
                              <Link to='services' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Services</p></Link> 
                        </div> 
                        <div className='footer-link'>
                              <Link to='products' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Products</p></Link> 
                        </div> 
                        <div className='footer-link'>
                              <Link to='gallery' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Gallery</p></Link> 
                        </div> 
                        
                        <div className='footer-link'>
                              <Link to='inquiries' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Write To Us</p></Link> 
                        </div>                  
                    </div>

             </div>

            

             {/* <div className='footer-inner-content'>
                    <h3>Our Services</h3>
                    <div className="footer-links-cover">
                      

                         <div className='footer-link'>
                              <Link to='telecom' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Telecom & Networking Services</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='power' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Power Line Design & Construction</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='road-construction' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Road Construction & Maintenance</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='support' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Support & Managed Services</p></Link> 
                        </div>
                          <div className='footer-link'>
                              <Link to='maintenance' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Preventive Maintenance</p></Link> 
                        </div>

                        <div className='footer-link'>
                            <Link to='emergency-response' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Emergency Response</p></Link> 
                        </div>

                         <div className='footer-link'>
                            <Link to='building-construction' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Building Construction</p></Link> 
                        </div>
                      
                      
                    </div>

             </div> */}

             <div className='footer-inner-content'>
<div className='footer-location'>
                      <h3>Office Locations</h3>
                      <p> 
                      <strong> Jinja Offices</strong> <br></br>
                       1. Lukanga Plaza Level 3, Room 9, Jinja City<br></br>2. Kamuli Road near Alshafa Hospital, Jinja City<br></br>
                       P. O. Box 901330, Jinja - Uganda
                       <br></br> <br></br>
   <strong> Gulu Offices</strong> <br></br>                     
Bank Lane, Pece, Gulu City P. O. Box 1128, Gulu – Uganda
                        </p>
                        
                     

                    </div>

                   

                    
                      

             </div>


                   <div className='footer-inner-content'>


                    <div className='footer-contacts'>
                      <h3>Call Us On</h3>
                      <p>
                +256200 950145<br></br>+256780 767136
                      
                      
                      </p>

                    </div>

                     <div className='footer-email'>
                      <h3>Write To Us At</h3>
                   {/* <Link to='#contact' onClick={scrollFunc} className='email-link'>   */}
                   <p>
                        info@beckotechengineering.com<br></br>
                     beckotecinternational@gmail.com
                   

{/* rejoconstructions500@gmail.com


rejoengineeringltd@gmail.com */}
                    </p>
                    {/* </Link>  */}
                      
                      </div>
                      <div className='footer-social-icons'>
                         <h3>Connect With Us On</h3>
                         <div className='footer-icons'>
                           
                                <a href='https://www.facebook.com/profile.php?id=100070919799271' target="_blank" rel='noreferrer'> <span>
<FaFacebookSquare></FaFacebookSquare>
                                 </span></a>
                          <a href='#' target="_blank" rel='noreferrer'>     <span>
<FaTwitterSquare></FaTwitterSquare>
                                 </span>
                                 </a>
                                  <a href='#' target="_blank" rel='noreferrer'>  <span>
                        <FaYoutube></FaYoutube>
                                 </span></a>

                            {/* <a href='#' target="_blank" rel='noreferrer'>       <span>
<FaLinkedin></FaLinkedin>
                                 </span>  
                                 </a> */}
                         </div>
                        
                        
                      </div>

             </div>

        
        
        
        </div>
        <div className='footer-bottom'>
            <p>    &copy; {new Date().getFullYear()} <span className="date"></span> Beckotech Engineering Limited | Website Developed By <a href="https://bensitesug.com" target="_blank" className="footer-link-a" rel="noopener noreferrer">BenSites</a></p>
          </div>
    </section>
  )
}

export default Footer