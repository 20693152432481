import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import {FaCheckCircle} from 'react-icons/fa';

const WhoWeAre = () => {

useEffect(()=>{
document.title = 'About Us | Beckotech Engineering Limited';

},[]);


  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./images/3.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0',
    paddingTop:'12.5rem'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3 quote-h3-a'>About Us</h2>
            
        </div>
    </section>

     
    <section className='section-cover who-we-are-cover' >
      {/* <h2 className="home-h2 wwa-h2">About Us</h2> */}
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                          <h3 className='title-h3'>Objectives</h3>
                          
                           <div className='vm-line'></div>
                          <ul>
                            <li>Quality and timely jobs to our clients.</li>
<li>Innovative and inventive system approach.</li>
<li>Staff training and development.</li>

                          </ul>
                          
                          <h3 className='title-h3'> Mission</h3>
                          <div className='vm-line'></div>
                          <p>
                          To be the largest innovative and quality engineering
provider.

</p>
  <h3 className='title-h3'> Goals</h3>
                          <div className='vm-line'></div>
                          <p>
                         Giving our clients the best yet pocket friendly
engineering services all around the globe.
</p>
                    </article>

                    


                 </div>

          </div>
    </section>

     {/* <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./images/11.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2>
              <h3 className='title-h3'>Our Core Values</h3>
              <div className='vm-line'></div>
              <div className='goals-list'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'>Transparency
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'>Integrity</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'>Ownership</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'> Innovation</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'>Ambition</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'>Customer Satisfaction</p>
                     </div>

                </div>
                   

           </article>

       </div>

  </section> */}

      
    
    <section className="cert-cover">
        <h3 className='title-h3 title-h3-a'>Company Structure</h3>
                          
                           <div className='vm-line'></div>
        <div>
          <img src='images/34.jpg' className='certification-image'></img>
        </div>
    </section>
    

    </>
  )
}

export default WhoWeAre