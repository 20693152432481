import React, {useEffect} from 'react';


const Gallery = () => {
  useEffect(()=>{
document.title = 'Products | Beckotech Engineering Limited';

},[]);
  return (
    <section className='section-cover gallery-cover' >
       <h2 className="home-h2 gallery-h2">Our Products</h2>
      <div className='content-container gallery'>
        <article className='gallery-content product-content'>
          <img src='./images/60.jpg' className='gallery-image product-image' alt='image'></img>
         </article>
         <article className='gallery-content product-content'>
          <img src='./images/61.jpg' className='gallery-image product-image' alt='image'></img>
         </article>
         <article className='gallery-content product-content'>
          <img src='./images/62.jpg' className='gallery-image product-image' alt='image'></img>
         </article>
         <article className='gallery-content product-content'>
          <img src='./images/63.jpg' className='gallery-image product-image' alt='image'></img>
         </article>
         <article className='gallery-content product-content'>
          <img src='./images/64.jpg' className='gallery-image product-image' alt='image'></img>
         </article>
         <article className='gallery-content product-content'>
          <img src='./images/65.jpg' className='gallery-image product-image' alt='image'></img>
         </article>
         <article className='gallery-content product-content'>
          <img src='./images/66.jpg' className='gallery-image product-image' alt='image'></img>
         </article>
         <article className='gallery-content product-content'>
          <img src='./images/67.jpg' className='gallery-image product-image' alt='image'></img>
         </article>
         <article className='gallery-content product-content'>
          <img src='./images/68.jpg' className='gallery-image product-image' alt='image'></img>
         </article>
         <article className='gallery-content product-content'>
          <img src='./images/69.jpg' className='gallery-image product-image' alt='image'></img>
         </article>
      
      </div>

    </section>
  )
}

export default Gallery