import Carousel from 'react-bootstrap/Carousel';

function WaterSupply() {
  return (
    <Carousel  indicators={false} className="goals-carousel" >
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/6a.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/6b.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/6c.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/6d.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/29a.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/29b.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/29c.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/29d.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/29e.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

    </Carousel>
  );
}

export default WaterSupply;