import Carousel from 'react-bootstrap/Carousel';

function Residential1() {
  return (
    <Carousel indicators={false} className='goals-carousel'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/14.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/15.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/16.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/17.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/18.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/19.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
       
    </Carousel>
  );
}

export default Residential1;