import Carousel from 'react-bootstrap/Carousel';

function ClassRoomBlocks() {
  return (
    <Carousel  indicators={false} className="goals-carousel " >
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/5a.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/5b.jpg"
          alt="Third slide"
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/5c.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/5d.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/5e.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/5f.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/5g.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/5h.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/8a.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/8b.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/44.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/45.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/46.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>

    </Carousel>
  );
}

export default ClassRoomBlocks;